import React, { useCallback, useEffect, useRef, useState } from "react";

import ReactGA from "react-ga4";

import { Box, Container, ThemeProvider } from "@mui/material";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AuthProvider } from "./context/auth";
import { AuthModalProvider } from "./context/authModal";
import ToastProvider from "./context/toast";

import Profile from "./Pages/UserDashboard/Profile";
// import Packages from './Pages/UserDashboard/Packages';
// import Payments from './Pages/UserDashboard/Payments';

import Editorial from "./Popup/Editorial";
import Disclaimer from "./Popup/Disclaimer";

import theme from "./Theme/Default/theme";

import axios from "./libraries/axios.js";
import ArticleContext from "./libraries/context2";

import Home from "./components/Home";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HomeCategory from "./components/HomeCategory";
import AtclContainer from "./components/AtclContainer";
import CategoryNavbar from "./components/CategoryNavbar";
import ProtectedRoute from "./components/ProtectedRoute";

import "./App.css";

import { CategoriesQuery } from "./server/query";

const gaTrackingId = "G-9B95PTS6EF";
ReactGA.initialize(gaTrackingId);

function App() {
	const [user, setUser] = useState();
	const [currentArticle, setCurrentArticle] = useState();
	const [navLinks, setNavLinks] = useState([]);
	const [category, setCategory] = useState([]);
	const [swtDic, setSwtDic] = useState(false);

	useEffect(() => {
		FetchCategory();
	}, [user]);

	const FetchCategory = async () => {
		try {
			await axios({
				method: "POST",
				data: { query: CategoriesQuery },
				headers: {
					Accept: `application/json`,
					"Content-Type": "application/json",
				},
			})
				.then((res) => {
					const data = res.data;
					if (data != null) {
						const updateCategory = data.data.categories;
						const updateHello = updateCategory?.map((item) => {
							return {
								id: item.id,
								link: `/${`${item.category}`.toLowerCase()}`,
								title: `${item.category}`,
							};
						});
						setNavLinks(updateHello);
						setCategory(updateCategory);
					} else {
						alert(res.data.errors[0].message);
					}
				})
				.catch((error) => {
					console.error("Category FetcingError ", error);
				});
		} catch (err) {
			console.error("CategoryError", err);
		}
	};

	return (
		<ToastProvider>
			<AuthProvider>
				<ArticleContext.Provider value={{ currentArticle, setCurrentArticle }}>
					<ThemeProvider theme={theme}>
						<AuthModalProvider>
							<Box
								className="app"
								minHeight="100vh"
								display="flex"
								flexDirection="column"
							>
								<Router>
									<Switch>
										<ProtectedRoute
											path="/dashboard/profile"
											component={Profile}
										/>
										<Route path="/article">
											<Header />
											<Box bgcolor={(theme) => theme.palette.grey["100"]}>
												<Container
													disableGutters
													maxWidth="xl"
													sx={{ bgcolor: "white" }}
												>
													<CategoryNavbar />
													<AtclContainer />
												</Container>
											</Box>
										</Route>
										{navLinks?.map((item) => (
											<Route path={item.link} key={item.link}>
												<Header />
												<Box
													bgcolor={(theme) => theme.palette.grey["100"]}
													flex="1 1 0"
													display="flex"
													flexDirection="column"
												>
													<Container
														disableGutters
														maxWidth="xl"
														sx={(theme) => ({
															bgcolor: theme.palette.grey["50"],
															flex: "1 1 0",
														})}
													>
														<CategoryNavbar />
														<HomeCategory />
													</Container>
												</Box>
												<Footer setSwtDic={setSwtDic} />
											</Route>
										))}
										<Route path="/">
											<Header />
											<Box bgcolor={(theme) => theme.palette.grey["100"]}>
												<Container
													maxWidth="xl"
													disableGutters
													sx={{ bgcolor: "white" }}
												>
													<NavBar />
													<CategoryNavbar />
													<Home />
												</Container>
											</Box>
											<Footer setSwtDic={setSwtDic} />
										</Route>
									</Switch>
								</Router>
							</Box>
							{swtDic && <Disclaimer setSwtDic={setSwtDic} swtDic={swtDic} />}
						</AuthModalProvider>
					</ThemeProvider>
				</ArticleContext.Provider>
			</AuthProvider>
		</ToastProvider>
	);
}

export default App;
