import React, { useContext, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import Axios from "./Axios";
import Loader from "./Loader.js";
import UpperPara from "./UpperPara";
import UpperPara2 from "./UpperPara2";

import { useToastContext } from "../context/toast";

import ArticleContext from "../libraries/context2";

import { FindArticlesByCategory } from "../server/query";

import "../css/CategoryUpper.css";
import { formatDate } from "../util/helper/index.js";

function CategoryUpper({ cName }) {
  const { displayToast } = useToastContext();
  const articleContext = useContext(ArticleContext);

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [selectedVolume, setSelectedVolume] = useState("all");
  const [selectedEdition, setSelectedEdition] = useState(null);

  useEffect(() => {
    fetchArticles();
  }, []);

  const displayedArticles = useMemo(() => {
    if (!selectedEdition) {
      return [];
    }

    const editionArticles = articles.filter(
      (article) => Math.floor(Number(article?.volume)) === selectedEdition,
    );

    if (selectedVolume === "all") {
      return editionArticles;
    }

    const selectedArticle = editionArticles.filter(
      (article) => article?.volume === selectedVolume,
    );

    return selectedArticle;
  }, [articles, selectedEdition, selectedVolume]);

  async function fetchArticles() {
    try {
      const query = FindArticlesByCategory({ categoryName: cName });
      const response = await Axios({ query });

      if (response.errors) {
        displayToast({
          message: "Something went wrong, Please try again.",
          mode: "error",
        });
        setLoading(false);
        return undefined;
      }

    
      const articlesWithCategory = response.data.articals
        .filter((article) => Boolean(article.categoryId))
        .map(parseArticle);

      const sortedArticlesByVolumeNo = articlesWithCategory.sort(
        (articleOne, articleOther) =>
          parseFloat(articleOther.volume) - parseFloat(articleOne.volume),
      );

      setArticles(sortedArticlesByVolumeNo);
      setSelectedEdition(
        Math.floor(Number(sortedArticlesByVolumeNo.at(0).volume)),
      );
      setLoading(false);
    } catch (error) {
      displayToast({
        message: "Something went wrong, Please try again.",
        mode: "error",
      });
      setLoading(false)
      return undefined;
    }
  }

  function showArticle(currentArticle) {
    articleContext.setCurrentArticle(currentArticle);
    window.localStorage.setItem("cArt", JSON.stringify(currentArticle));
    history.push("/article", currentArticle);
  }

  const categoryEditions = useMemo(() => {
    const duplicatedEditions = articles.map((article) =>
      Math.floor(article.volume),
    );
    const editions = Array.from(new Set(duplicatedEditions));

    return editions;
  }, [articles]);

  const editionVolumes = useMemo(() => {
    if (!selectedEdition) {
      return [];
    }

    const editionArticlesVolume = articles.filter(
      (article) => Math.floor(Number(article?.volume)) === selectedEdition,
    );

    const volumes = editionArticlesVolume.map((article) => article?.volume);

    return volumes;
  }, [articles, selectedEdition]);

  function handleChangeEdition(event) {
    setSelectedEdition(event.target.value);
    setSelectedVolume("all");
  }

  function handleChangeVolume(event) {
    setSelectedVolume(event.target.value);
  }

  return (
    <Box sx={{ p: { xs: 2, md: 4 } }}>
      <Loader visibility={loading} />
      <div className="mainUpperContainer">
        <div className="categoryUp">
          <div className="categoryHeading">{cName}</div>
          <div className="categoryLine" />
        </div>

        {articles?.length > 0 &&<Box display="inline-flex " flexWrap="wrap" marginTop={3} gap={3}>
          <Box display="inline-flex" gap={1} alignItems="center">
            <label id="articleVolume">
              <Typography>Volume</Typography>
            </label>
            <Select
              size="small"
              labelId="articleVolume"
              value={selectedVolume}
              onChange={handleChangeVolume}
              sx={{ minWidth: "150px" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: "20rem" } } }}
            >
              <MenuItem value="all">All Articles</MenuItem>
              {editionVolumes.map((volume) => (
                <MenuItem value={volume} key={volume}>
                  {volume}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box display="inline-flex" gap={1} alignItems="center">
            <label id="articleVolume">
              <Typography>Edition</Typography>
            </label>
            <Select
              size="small"
              labelId="articleVolume"
              value={selectedEdition}
              onChange={handleChangeEdition}
              sx={{ minWidth: "100px" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: "20rem" } } }}
            >
              {categoryEditions.map((edition) => (
                <MenuItem value={edition} key={edition}>
                  {edition}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>}
      </div>

       { !loading &&
      articles?.length == 0 && <div className="pageNotFound">
        <h1>This page does not have any articles.</h1>
      </div>
       }         
      {displayedArticles?.map((currentArticle) => (
        <>
          <div className="upperContainer">
            <p className="upperHeading">{currentArticle.title}</p>
            <p className="topicUpperContainer">{currentArticle.categoryName}</p>
            <p className="dateUpperContainer">
              Updated{" "}
              {currentArticle?.updatedAt &&
                formatDate(new Date(currentArticle.updatedAt))}
            </p>
          </div>
          <div className="box">
            <div className="UCParagaraph">
              <UpperPara
                para={currentArticle.pharagraph01}
                id={currentArticle.u1}
              />
              <UpperPara2
                para={currentArticle.pharagraph02}
                id={currentArticle.s1}
              />
            </div>
            <div className="upperContainerButton">
              <Button
                onClick={() => showArticle(currentArticle)}
                variant="outlined"
                endIcon={
                  <InputAdornment position="start">
                    <KeyboardDoubleArrowRightIcon
                      fontSize="small"
                      color="secondary"
                    />
                  </InputAdornment>
                }
              >
                Read more
              </Button>
            </div>
          </div>
        </>
      ))}
    </Box>
  );
}

function parseArticle(article, index) {
  return {
    id: article.id,
    categoryId: article.categoryId,
    categoryName: article.categoryName,
    slotId: article.slotId,
    title: article.title,
    subTitle: article.subTitle,
    editer: article.editer,
    volume: article.volume,
    main: article.main,
    slotNo: article.slotNo,
    homePlace: article.homePlace,
    titleImage: article.titleImage,
    subTitlteImg: article.subTitlteImg,
    pharagraph01: article.pharagraph01,
    pharagraph02: article.pharagraph02,
    pharagraph03: article.pharagraph03,
    createdAt: article.createdAt,
    updatedAt: article.updatedAt,
    u1: `u1${index}`,
    s1: `s1${index}`,
  };
}

export default CategoryUpper;
