import { yupResolver } from "@hookform/resolvers/yup";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import React, { useRef, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";

import { useToastContext } from "../../context/toast";

import Axios from "../Axios";

import { FormContextProvider } from "./formContext";
import EmailStep, { schema as emailSchema } from "./EmailStep";
import EmailConfirmationStep, {
  schema as emailConfirmationSchema,
} from "./EmailConfirmationStep";
import PersonalInformationStep, {
  schema as personalInformationSchema,
} from "./PersonalInformationStep";

import {
  confirmVerificationCode,
  createUser,
  sendVerificationEmail,
} from "../../server/mutation";

import firebase, { FirebaseError } from "../../service/firebase/";

import useMultiStepForm from "../../util/hook/useMultiStepForm";

/**
 * @typedef {() => void} VoidFunc
 * @param {{
 *   open: boolean;
 *   onClose: VoidFunc;
 *   openLoginModal: VoidFunc;
 * }} props
 */
function Register({ open, onClose, openLoginModal }) {
  const { displayToast } = useToastContext();
  const {
    CurrentStep,
    nextStep,
    previousStep,
    schema,
    currentStepIndex,
    reset,
  } = useMultiStepForm({
    formSteps: [EmailStep, EmailConfirmationStep, PersonalInformationStep],
    schemas: [emailSchema, emailConfirmationSchema, personalInformationSchema],
  });

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      address: "",
      education: "",
      occupation: "",
      verificationCode: "",
      isSubscribed: true,
    },
    resolver: schema && yupResolver(schema),
  });

  const [title, setTitle] = useState("");
  const [dialogMaxWidth, setDialogMaxWidth] = useState(
    /** @type {import("@material-ui/core/styles/createBreakpoints").Breakpoint} */ (
      "xs"
    ),
  );

  const referenceId = useRef(/** @type {null | string} */ (null));

  function resetAndClose() {
    form.reset();
    reset();
    onClose();
  }

  async function onSubmit(data) {
    if (currentStepIndex === 0) {
      const isSuccessful = await sendVerificationCode(data);
      if (!isSuccessful) return undefined;
    }

    if (currentStepIndex === 1) {
      const isSuccessful = await verifyCode(data);
      if (!isSuccessful) return undefined;
    }

    if (currentStepIndex === 2) {
      const isSuccessful = await register(data);
      if (!isSuccessful) return undefined;
    }

    nextStep();
  }

  async function sendVerificationCode(data) {
    try {
      const query = sendVerificationEmail({ email: data.email });
      const response = await Axios({ query });

      if (response.errors) {
        displayToast({ mode: "error", message: "Something went wrong." });
        return false;
      }

      if (response.data.sendVerificationEmail.__typename === "FieldError") {
        const error = response.data.sendVerificationEmail;
        if (error.path === "email")
          form.setError("email", { message: error.message });
        return false;
      }

      referenceId.current = response.data.sendVerificationEmail.referenceId;
      return true;
    } catch (error) {
      displayToast({ mode: "error", message: "Something went wrong." });
      return false;
    }
  }

  async function verifyCode(data) {
    try {
      if (!referenceId.current) return false;

      const query = confirmVerificationCode({
        code: data.verificationCode,
        referenceId: referenceId.current,
      });
      const response = await Axios({ query });

      if (response.errors) {
        displayToast({ mode: "error", message: "Something went wrong." });
        return false;
      }

      if (response.data.confirmVerificationCode.success === false) {
        form.setError("verificationCode", {
          message: "Invalid verification code.",
        });
        return false;
      }

      return true;
    } catch (error) {
      displayToast({ mode: "error", message: "Something went wrong." });
      return false;
    }
  }

  async function register(data) {
    try {
      const firebaseResponse = await firebase.register(
        data.email,
        data.password,
      );
      const uid = firebaseResponse.user?.uid;
      if (typeof uid === "undefined") return false;

      const query = createUser({ ...data, id: uid });
      const response = await Axios({ query });

      if (response.errors) {
        await firebase.delete();
        displayToast({ mode: "error", message: "Something went wrong." });
        return false;
      }

      if (response.data.createUser.__typename === "FieldError") {
        const error = response.data.createUser;
        await firebase.delete();
        form.setError(error.path, { message: error.message });
        return false;
      }

      displayToast({ message: "Registered successfully." });
      onClose();
      return true;
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case "auth/email-already-in-use":
            form.setError("email", {
              message: "Email is already associated with an account.",
            });
            return false;
          case "auth/too-many-requests":
            displayToast({
              message: "Many request made please wait and try again.",
              mode: "info",
            });
            return false;
        }
      }

      displayToast({
        message: "Sorry, Something went wrong.",
        mode: "error",
      });
      return false;
    }
  }

  return (
    <FormContextProvider
      value={{
        nextStep,
        previousStep,
        openLoginModal,
        onClose: resetAndClose,
        referenceId,
        setTitle,
        setDialogMaxWidth,
      }}
    >
      <FormProvider {...form}>
        <Dialog
          open={open}
          onClose={resetAndClose}
          maxWidth={dialogMaxWidth}
          fullWidth
        >
          <DialogTitle>
            <Box
              display="grid"
              gridTemplateColumns="1fr auto"
              alignItems="center"
            >
              <Typography
                variant="h6"
                fontFamily="var(--mont)"
                fontWeight={500}
              >
                {title}
              </Typography>
              <IconButton onClick={resetAndClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <CurrentStep />
            </form>
          </DialogContent>
        </Dialog>
      </FormProvider>
    </FormContextProvider>
  );
}

export default Register;
