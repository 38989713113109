import { yupResolver } from "@hookform/resolvers/yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CloseIcon from "@material-ui/icons/Close";

import React, { useState } from "react";

import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";

import { useToastContext } from "../context/toast";

import PasswordField from "./password-field";
import ForgotPassword from "./ForgotPassword";

import firebase, { FirebaseError } from "./../service/firebase";

import "../css/Login.css";

/**
 * @param {object} props
 * @param {() => void} props.onClose
 * @param {boolean} props.open
 * @param {() => void} props.openRegisterModal
 * @param {() => void} props.openModal
 * @returns {JSX.Element}
 */
function Login({ onClose, open, openRegisterModal, openModal }) {
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const { displayToast } = useToastContext();
  const form = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
  });

  function handleClickRegister() {
    resetAndCloseModal();
    openRegisterModal();
  }

  function handleClickForgotPassword() {
    resetAndCloseModal();
    setIsForgotPasswordOpen(true);
  }

  function handleClosePasswordModal() {
    setIsForgotPasswordOpen(false);
    openModal();
  }

  function resetAndCloseModal() {
    form.reset();
    onClose();
  }

  /** @param {yup.InferType<typeof schema>} data */
  async function onSubmit(data) {
    try {
      await firebase.login(data.email, data.password);

      displayToast({ message: "Welcome back.", mode: "success" });
      resetAndCloseModal();
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case "auth/user-not-found":
            form.setError("email", {
              message: "Email is not associated with an account.",
            });
            return undefined;
          case "auth/user-disabled":
            displayToast({
              message: "Seems like your account has been disabled.",
              mode: "info",
            });
            return undefined;
          case "auth/wrong-password":
            form.setError("password", { message: "Incorrect password." });
            return undefined;
          case "auth/too-many-requests":
            displayToast({
              message: "Many request made please wait and try again.",
              mode: "info",
            });
            return undefined;
        }
      }

      displayToast({ message: "Sorry, Something went wrong.", mode: "error" });
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={resetAndCloseModal}
        aria-describedby="modalTitle"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <Box
            className="registerTopic"
            display="grid"
            gridTemplateColumns="1fr auto"
            alignItems="center"
            mb={2}
          >
            <Typography id="modalTitle" variant="h6">
              Login
            </Typography>
            <IconButton onClick={resetAndCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack mb={3} gap={2}>
              <label htmlFor="email" className="inputText">
                Email
                <Controller
                  control={form.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      size="small"
                      id="email"
                      variant="outlined"
                      type="text"
                      inputMode="email"
                      placeholder="Sam.info@gmail.com"
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      {...field}
                      inputRef={field.ref}
                    />
                  )}
                />
              </label>

              <div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <label>Password</label>
                  {form.formState.isSubmitted && (
                    <Button
                      type="button"
                      sx={{ p: 0, px: 0.5 }}
                      onClick={handleClickForgotPassword}
                    >
                      <Typography variant="caption">
                        Forgot password?
                      </Typography>
                    </Button>
                  )}
                </Box>
                <Controller
                  control={form.control}
                  name="password"
                  render={({ field, fieldState }) => (
                    <PasswordField
                      sx={{ mt: 0.5 }}
                      fullWidth
                      size="small"
                      autoComplete="current-password"
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )}
                />
              </div>
            </Stack>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              style={{ textTransform: "none" }}
              disabled={form.formState.isSubmitting}
            >
              Login
            </Button>
          </form>

          <Box
            mt={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body2">Not a member?</Typography>
            <Typography variant="body2" component={Button} onClick={handleClickRegister} py={0} px={.5}>
              Register
            </Typography>
          </Box>

          {/* <img className='loginRespectLogoRed' src={respectLogoRed} alt='' /> */}
        </DialogContent>
      </Dialog>
      <ForgotPassword
        open={isForgotPasswordOpen}
        onClose={handleClosePasswordModal}
      />
    </>
  );
}

const schema = yup.object().shape({
  email: yup.string().trim().required().email().label("Email"),
  password: yup.string().trim().required().label("Password"),
});

export default Login;
