import React from "react";

import HomeBodyBottom from "./HomeBodyBottom";
import HomeBodyLeft from "./HomeBodyLeft";
import HomeBodyRight from "./HomeBodyRight";

import "../css/Home.css";

function Home() {
  return (
    <div className="home">
      <div className="body">
        <HomeBodyRight />
        <HomeBodyLeft />
      </div>
      <HomeBodyBottom />
    </div>
  );
}

export default Home;
