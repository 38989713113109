import React from "react";
import "../css/HomeBodyRight.css";
import HomeBodyRightContainer from "./HomeBodyRightContainer";

function HomeBodyRight() {
  return (
    <div className="home_bodyRight">
      <HomeBodyRightContainer />

      <div className="bodyContentRow_heading">
        <span>
          We would like to know more about you so that we can customize the
          articles to your interests. So please register.
        </span>
      </div>
    </div>
  );
}

export default HomeBodyRight;
