import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import React, { useEffect } from "react";

import * as yup from "yup";

import {
  Controller,
  useFormContext as useHookFormContext,
} from "react-hook-form";

import { useFormContext } from "./formContext";

function EmailStep() {
  const form = useHookFormContext();
  const { openLoginModal, onClose, setTitle, setDialogMaxWidth } =
    useFormContext();

  useEffect(() => {
    setTitle("Enter Your Email");
    setDialogMaxWidth("xs");
  }, []);

  function handleClickLogin() {
    onClose();
    openLoginModal();
  }

  return (
    <>
      <Stack gap={2} sx={{ width: "100%" }} alignItems="center">
        <FormControl fullWidth>
          <Box component="label" htmlFor="email" sx={{ mb: 0.5 }}>
            Email
          </Box>
          <Controller
            name="email"
            control={form.control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                id="email"
                size="small"
                inputMode="email"
                autoComplete="email"
                inputRef={ref}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </FormControl>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={form.formState.isSubmitting}
        >
          Submit
        </Button>
      </Stack>
      <Box
        display="flex"
        alignItems="center"
        mt={0.5}
        mx="auto"
        width="fit-content"
      >
        <Typography>Already have an account?&nbsp;</Typography>
        <Button
          size="small"
          onClick={handleClickLogin}
          sx={{ minWidth: 0, width: "fit-content" }}
        >
          Login
        </Button>
      </Box>
    </>
  );
}

const schema = yup.object({
  email: yup.string().trim().required().email().label("Email"),
});

export default EmailStep;
export { schema };
