import React, { createContext, useContext, useState, useMemo } from "react";

import Login from "../components/Login";
import Register from "../components/Register";

const AuthModalsContext = createContext(
  /**
   * @type {null | {
   *   openLoginModal: () => void;
   *   openRegisterModal: () => void;
   * }}
   */ (null),
);

function AuthModalProvider(
  /** @type {{ children: React.ReactNode }} */ { children },
) {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const closeLoginModal = () => setIsLoginModalOpen(false);
  const openLoginModal = () => setIsLoginModalOpen(true);
  const closeRegisterModal = () => setIsRegisterModalOpen(false);
  const openRegisterModal = () => setIsRegisterModalOpen(true);

  const contextValue = useMemo(
    () => ({ openLoginModal, openRegisterModal }),
    [],
  );

  return (
    <AuthModalsContext.Provider value={contextValue}>
      <Login
        open={isLoginModalOpen}
        onClose={closeLoginModal}
        openModal={openLoginModal}
        openRegisterModal={openRegisterModal}
      />
      <Register
        open={isRegisterModalOpen}
        onClose={closeRegisterModal}
        openLoginModal={openLoginModal}
      />

      {children}
    </AuthModalsContext.Provider>
  );
}

function useAuthModalContext() {
  const context = useContext(AuthModalsContext);

  if (!context)
    throw new Error("Component should be within the login context.");

  return context;
}

export { useAuthModalContext, AuthModalProvider };
