import { yupResolver } from "@hookform/resolvers/yup";

import { Checkbox, FormControlLabel, Typography, styled } from "@mui/material";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";

import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";

import Axios from "./../../components/Axios";

import Layout from "./Components/Layout";

import { UserQuery } from "../../server/query";

import { updateUser } from "../../server/mutation";
import { useToastContext } from "./../../context/toast";

// const AcceptedImageTypes = ["image/png", "image/jpeg", "image/jpg"];

function Profile() {
  const { displayToast } = useToastContext();

  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "",
      address: "",
      education: "",
      occupation: "",
      profilePicture: "",
      isSubscribed: true,
    },
    resolver: yupResolver(schema),
  });

  // const profilePicture = useRef();

  useEffect(() => {
    async function fetchUserDetails() {
      const response = await Axios({ query: UserQuery });
      if (response.errors) {
        displayToast({
          message: "Something went wrong, Unable to get your details.",
          mode: "error",
        });
        return undefined;
      }

      form.reset(response.data.user);
    }

    fetchUserDetails();
  }, []);

  /* ----------------------------- Event Handlers ----------------------------- */

  // function handleChangeProfilePicture(event) {
  //   const { files } = event.target;
  //   if (files.length > 0) {
  //     const tempImageUrl = URL.createObjectURL(files.item(0));
  //     setProfilePictureUrl(tempImageUrl);
  //     return undefined;
  //   }
  //   profilePicture.current.value = "";
  // }

  // function handleDeleteProfilePicture(event) {
  //   profilePicture.current.value = "";
  //   setProfilePictureUrl(undefined);
  // }

  async function onSubmit(data) {
    const response = await Axios({ query: updateUser(data) });

    if (response.errors) {
      displayToast({
        mode: "error",
        message: "Something went wrong, Failed to update.",
      });
      return undefined;
    }

    if (response.data.updateUser.__typename === "FieldError") {
      const error = response.data.updateUser;
      form.setError(error.path, { message: error.message });
      return undefined;
    }

    displayToast({ message: "Updated successfully." });
    form.reset(response.data.updateUser);
  }

  return (
    <Layout>
      <Container maxWidth="sm" sx={{ py: 3 }}>
        {/* <FormControl sx={{ mb: 2 }}>
            <Box
              mb={2}
              gap={2}
              display="grid"
              width="fit-content"
              alignItems="center"
              gridTemplateAreas={[
                "'avatar primary-action' 'avatar secondary-action'",
                "'avatar primary-action secondary-action'",
              ]}
            >
              <Avatar
                src={profilePictureUrl}
                sx={{
                  width: "80px",
                  height: "unset",
                  aspectRatio: "1",
                  gridArea: "avatar",
                }}
              />

              <Button
                disableElevation
                component="label"
                variant="contained"
                htmlFor="profilePicture"
                sx={{ gridArea: "primary-action" }}
              >
                Upload new picture
              </Button>
              <Button
                color="soft"
                disableElevation
                variant="contained"
                onClick={handleDeleteProfilePicture}
                sx={{ gridArea: "secondary-action" }}
              >
                Delete
              </Button>
              <input
                hidden
                type="file"
                id="profilePicture"
                ref={profilePicture}
                accept=".jpeg,.jpg,.png"
                style={{ display: "none" }}
                onChange={handleChangeProfilePicture}
              />
            </Box>
            <FormHelperText error={Boolean(errors.profilePicture)}>
              {errors.profilePicture}
            </FormHelperText>
          </FormControl> */}

        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box
            display="grid"
            gap={2}
            gridTemplateColumns={{ md: "repeat(2,1fr)" }}
            mb={2}
          >
            <FormControl fullWidth>
              <FormLabel>First Name</FormLabel>
              <Controller
                name="firstName"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    size="small"
                    helperText={fieldState.error?.message}
                    error={Boolean(fieldState.error)}
                    inputRef={ref}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Last Name</FormLabel>
              <Controller
                name="lastName"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    size="small"
                    helperText={fieldState.error?.message}
                    error={Boolean(fieldState.error)}
                    inputRef={ref}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Mobile Number</FormLabel>
              <Controller
                name="mobileNumber"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    size="small"
                    helperText={fieldState.error?.message}
                    error={Boolean(fieldState.error)}
                    inputRef={ref}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Email</FormLabel>
              <Controller
                name="email"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    disabled
                    size="small"
                    helperText={fieldState.error?.message}
                    error={Boolean(fieldState.error)}
                    inputRef={ref}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Education</FormLabel>
              <Controller
                name="education"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    size="small"
                    helperText={fieldState.error?.message}
                    error={Boolean(fieldState.error)}
                    inputRef={ref}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <FormLabel>Occupation</FormLabel>
              <Controller
                name="occupation"
                control={form.control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <TextField
                    size="small"
                    helperText={fieldState.error?.message}
                    error={Boolean(fieldState.error)}
                    inputRef={ref}
                    {...field}
                  />
                )}
              />
            </FormControl>

            <Box sx={{ gridColumn: "span 2" }}>
              <FormControl fullWidth>
                <FormLabel>Address</FormLabel>
                <Controller
                  name="address"
                  control={form.control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      spellCheck="false"
                      rows={2}
                      multiline
                      size="small"
                      helperText={fieldState.error?.message}
                      error={Boolean(fieldState.error)}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
              </FormControl>

              <Controller
                control={form.control}
                name="isSubscribed"
                render={({ field: { value, ...field } }) => (
                  <FormControlLabel
                    sx={{ my: 1 }}
                    label={
                      <Typography variant="caption" sx={{ userSelect: "none" }}>
                        Receive Newsletter.
                      </Typography>
                    }
                    control={
                      <Checkbox
                        size="small"
                        {...field}
                        checked={Boolean(value)}
                        inputRef={field.ref}
                        id="newsletter"
                      />
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            disabled={form.formState.isSubmitting}
          >
            Save Profile
          </Button>
        </form>
      </Container>
    </Layout>
  );
}

const Button = styled(MuiButton)({
  textTransform: "none",
  fontSize: "1rem",
  lineHeight: 1.5,
});

const schema = yup.object({
  email: yup.string().required().email().lowercase().label("Email"),
  firstName: yup.string().trim().required().label("First Name"),
  lastName: yup.string().trim().required().label("Last Name"),
  education: yup.string().trim().required().label("Education"),
  occupation: yup.string().trim().required().label("Occupation"),
  isSubscribed: yup.boolean().required().label("Newsletter"),
  mobileNumber: yup
    .string()
    .trim()
    .required()
    .label("Mobile Number")
    .matches(
      /^\+[1-9]\d{10,14}$/,
      "Invalid Mobile Number format eg:+947XXXXXXXX",
    ),
  address: yup
    .string()
    .trim()
    .label("Address")
    .nullable()
    .defined()
    .transform((value) => value || null),
});

// profilePicture: yup
// .mixed()
// .nullable()
// .test(
//   "File Format",
//   `Only ${AcceptedImageTypes} are supported`,
//   (value) => !value || (value && AcceptedImageTypes.includes(value.type)),
// )
// .test(
//   "File Size",
//   "File is too large make sure file is at most 2Mb",
//   (value) => !value || (value && value.size <= 2097152),
// )
// .label("Profile Picture")

export default Profile;
