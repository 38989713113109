import React, {
  useRef,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";

import { Alert, Snackbar } from "@mui/material";

/**
 * @typedef {"error" | "success" | "info" | "warning"} AlertMode
 *
 * @typedef {{
 *   displayToast: (params: {
 *     message: string;
 *     mode?: AlertMode;
 *     timeout?: number;
 *   }) => void;
 * }} ContextValue
 */

const ToastContext = createContext(/** @type {ContextValue | null} */ (null));

/**
 * A provider component that displays toast notifications.
 * @param {Object} props - The component props.
 * @param {React.ReactElement} props.children - The child components.
 * @returns {React.ReactNode} The rendered component.
 */
function ToastProvider({ children }) {
  const [toastOpen, setToastOpen] = useState(false);

  /** @type {{ message: string; mode: AlertMode; timeout: number }} */
  const toastProperties = useRef({
    message: "",
    mode: "success",
    timeout: 3000,
  }).current;

 /** @type {ContextValue["displayToast"]} */
  const displayToast = useCallback(
    ({ message, mode = "success", timeout = 3000 }) => {
      toastProperties.message = message;
      toastProperties.timeout = timeout;
      toastProperties.mode = mode;

      setToastOpen(true);
    },
    [],
  );

  const toastValue = useMemo(() => ({ displayToast }), []);

  return (
    <ToastContext.Provider value={toastValue}>
      <Snackbar
        open={toastOpen}
        autoHideDuration={toastProperties.timeout}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={() => setToastOpen(false)}
      >
        <Alert severity={toastProperties.mode}>{toastProperties.message}</Alert>
      </Snackbar>

      {children}
    </ToastContext.Provider>
  );
}

function useToastContext() {
  const context = useContext(ToastContext);

  if (context === null)
    throw new Error(
      "Component should be inside the toast context provider to use toastContext.",
    );

  return context;
}

export default ToastProvider;
export { useToastContext };
