import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import DIC from "../images/dic.png";
import profile from "../images/profile.png";
import SIGN from "../images/sign.png";

function Editorial({ open, onClose }) {
  const style_common = {
    textAlign: "left",
    lineHeight: 1.5,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "13pt",
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <DialogActions>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>

      <DialogContent sx={{ p: { xs: 6, md: 10 } }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
        <div className="gap-5">
        <img
         className=""
            src={profile}
            width={130}
            height={130}
            style={{ objectFit: "contain", maxWidth: "60%", height: "auto" ,alignSelf: "flex-start" , padding:"2"}}
          />
          
          
          <img
          className="ml-20"
            src={DIC}
            width={250}
            height={120}
            style={{ objectFit: "contain", maxWidth: "75%", height: "auto" ,alignSelf: "flex-end" , padding:"2" }}
          />
        </div>

          
          <br />
          <p
            style={{
              ...style_common,
              fontWeight: 700,
              textAlign: "center",
              font: "bold",
              fontSize: "14px",
              maxWidth: "25%"
              
            }}
          >
            or <br />
            Knowledge is our sword we fight with
          </p>
          <br />
          
        </div>
        <br />
        <br />
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          
        <p style={{style_common, fontWeight: 700}} > Editorial for the 8th Edition </p>
        </div>
        <br />
        <br />

        <p style={style_common}>
        Dear reader, and above all, dear young reader, 
        </p>
        <br />
        <p style={style_common}>
        the eighth issue of &quot;respect&quot; has been finalised after long discussions about the content.
        </p>
        <br />

        <p style={style_common}>
        Unfortunately, this issue was delayed because I suddenly had a virus on my external drive and, as in real life, I forgot to make a backup because I was so busy. The result was a time-consuming restoration of the files. But lesson learned, now every file is backed up on completion.
        </p>
        
        <br />

        <p style={style_common}>
        One of the things that surprised me was the importance of horoscopes in the lives of Sri Lankans. Many personal events are based on what horoscopes say. For some people it is a strict guideline, for others it helps them make decisions. That&#39;s why I thought it might be time to take a look at what horoscopes say, with horoscopes and about horoscopes. 
        </p>
        <br />
        <p style={style_common}>
        The bipolar structure of the &quot;world order&quot; is slowly disintegrating and a multipolar structure in the form of the BRICS states is making its way onto the world stage. Hence a tentative attempt at explanation in the form of a few articles. It will be interesting to see how things develop and how the BRICS countries organise themselves.
        </p>
        <br />
        <p style={style_common}>
        Dear readers, I leave you to read this 8th edition and enjoy it.
        </p>
        <br />

        <div>
          <p>Sincerely</p>
          <br />
          <img
            src={SIGN}
            width={100}
            height="auto"
            style={{ objectFit: "contain" }}
          />
          <br />
          <p>Editor</p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Editorial;
