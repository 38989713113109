import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemButton from "@mui/material/ListItemButton";

import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
// import PaymentIcon from "@mui/icons-material/Payment";
// import PackageIcon from "@mui/icons-material/Inventory";
import ProfileIcon from "@mui/icons-material/AccountCircle";

import ListItemLink from "./ListItemLink.jsx";

import { PRIMARY, SECONDARY } from "../../../Theme/Default/colors";

import Logo from "./../../../images/logoRed.png";

import firebase from "./../../../service/firebase";
import { Container } from "@mui/material";

function Layout({ children }) {
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const history = useHistory();

	if (isMobile)
		return (
			<Box>
				<MobileNavigation links={links} />
				<div>{children}</div>
			</Box>
		);

	async function handleLogout() {
		await firebase.logout();
		history.push("/");
	}

	return (
		<Box bgcolor={(theme) => theme.palette.grey["300"]}>
			<Container
				maxWidth="xl"
				disableGutters
				sx={(theme) => ({ bgcolor: theme.palette.grey["100"] })}
			>
				<Box display="grid" gridTemplateColumns="auto 1fr" minHeight="100vh">
					<aside
						style={{
							paddingInline: ".5em",
							height: "100%",
							backgroundColor: SECONDARY,
						}}
					>
						<img
							src={Logo}
							alt="Logo"
							width={200}
							height={100}
							style={{ padding: "1em" }}
						/>
						<nav>
							<List sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
								{links.map(({ label, ...linkProps }) => (
									<ListItemLink
										key={label}
										activeStyle={{ backgroundColor: PRIMARY }}
										{...linkProps}
									>
										{label}
									</ListItemLink>
								))}
							</List>
						</nav>
						<Divider variant="middle" sx={{ my: 1 }} />
						<ListItemButton onClick={handleLogout}>
							<ListItemIcon>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText>Logout</ListItemText>
						</ListItemButton>
					</aside>
					<main>{children}</main>
				</Box>
			</Container>
		</Box>
	);
}

function MobileNavigation({ links }) {
	const [isNavOpen, setIsNavOpen] = useState(false);

	return (
		<Box sx={({ palette }) => ({ px: 2, bgcolor: palette.secondary.main })}>
			<Box display="grid" gridTemplateAreas='"logo menu-icon"' p={2}>
				<img
					src={Logo}
					alt="Logo"
					width={85}
					height={40}
					style={{ gridArea: "logo" }}
				/>
				<IconButton
					role="switch"
					aria-controls="primary-navigation"
					onClick={() => setIsNavOpen((current) => !current)}
					sx={{ ml: "auto", display: "flex", gridArea: "menu-icon" }}
				>
					<MenuIcon />
				</IconButton>
			</Box>
			<Box
				component="nav"
				id="primary-navigation"
				sx={{
					transition: "all .25s ease",
					display: "grid",
					gridTemplateRows: isNavOpen ? "1fr" : "0fr",
				}}
			>
				<Box overflow="hidden">
					<List
						sx={{ py: 2, display: "flex", flexDirection: "column", gap: 2 }}
					>
						{links.map(({ label, ...linkProps }) => (
							<ListItemLink
								key={label}
								activeStyle={{ backgroundColor: PRIMARY }}
								{...linkProps}
							>
								{label}
							</ListItemLink>
						))}
						<li>
							<ListItemButton>
								<ListItemIcon>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText>Logout</ListItemText>
							</ListItemButton>
						</li>
					</List>
				</Box>
			</Box>
		</Box>
	);
}

const links = [
	{ to: "profile", icon: ProfileIcon, label: "Profile" },
	// { to: 'packages', icon: PackageIcon, label: 'Packages' },
	// { to: 'payments', icon: PaymentIcon, label: 'Payments' },
];

export default Layout;
