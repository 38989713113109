import Skeleton from "@mui/material/Skeleton";

import React, { useEffect, useState } from "react";

import Axios from "./Axios";
import BodyLeftContainer from "./BodyLeftContainer";

import * as Slots from "../Config/Slots";

import { SlotsWithArticleQuery } from "../server/query";

import "../css/HomeBodyLeft.css";
import "../css/BodyLeftContainer.css";

function HomeBodyLeft() {
  const [article, setArticle] = useState([]);

  useEffect(() => {
    FetchArticle();
  }, []);

  useEffect(() => {
    if (article?.length !== 0) {
      article?.map((item) => {
        window.document.querySelector(`#${item?.span}`).innerHTML = item?.para1;
        let AllParagraphs = window.document.querySelectorAll(
          `#${item?.span} p`,
        );
        AllParagraphs.forEach((item1) => {
          if (item1.querySelector("span")) {
            item1.querySelector("span").style.lineHeight = "1.5";
            // item1.querySelector("span").style.fontSize = "12pt";
            item1.querySelector("span").style.fontFamily =
              "Arial, Helvetica, sans-serif";
          } else {
            item1.style.lineHeight = "1.5";
            // item1.style.fontSize = "12pt";
            item1.style.fontFamily = "Arial, Helvetica, sans-serif";
          }
        });
      });
    }
  }, [article]);

  const FetchArticle = () => {
    Axios({ query: SlotsWithArticleQuery, title: "articals" }).then((res) => {
      const newFirst = res?.data;
      if (newFirst && newFirst?.slots !== null) {
        const filterArticle = newFirst?.slots.filter(
          (item) =>
            item?.artical?.main === true &&
            item.place === "SECOND" &&
            (item?.slot === Slots?.secondSlot1 ||
              item?.slot === Slots?.secondSlot2 ||
              item?.slot === Slots?.secondSlot3 ||
              item?.slot === Slots?.secondSlot4 ||
              item?.slot === Slots?.secondSlot5),
        );
        const updateSlotV = filterArticle?.sort(
          (a, b) => parseInt(a?.slot) - parseInt(b?.slot),
        );
        let count = 0;
        const updateArticles = updateSlotV?.map((item) => {
          const para = item?.artical?.pharagraph01;
          count++;
          return {
            id: item?.artical?.id,
            category: item?.artical?.categoryName,
            image: item?.artical?.titleImage,
            heading: item?.artical?.title,
            para1: para,
            slotNo: item?.artical?.slotNo,
            place: item?.artical?.homePlace,
            all: item?.artical,
            span: `leftPara${count}`,
            updateAt: item?.artical?.updateAt,
          };
        });
        setArticle(updateArticles);
      } else
        console.error(
          `Errors articles on ${JSON.stringify(newFirst?.errors[0]?.message)}`,
        );
    });
  };

  return (
    <div className="home_bodyLeft">
      {article.length !== 0 &&
        article?.map((item) => {
          if (item?.place === "SECOND") {
            return (
              <BodyLeftContainer
                key={item.id}
                category={item?.category}
                title={item?.heading}
                para={item?.para1}
                image={item?.image}
                all={item?.all}
                span={item?.span}
              />
            );
          }
        })}

      {article.length === 0 && (
        <>
          <SkeletonParagraph />
          <SkeletonParagraph />
        </>
      )}
    </div>
  );
}

const SkeletonParagraph = React.memo(() => (
  <div className="bodyLeftContainer">
    <div className="textContainer">
      <div className="left_containerHeading">
        <span>
          <Skeleton variant="text" style={{ width: "60%" }} />
        </span>
      </div>
      <div className="left_containerContent">
        <span>
          <Skeleton style={{ height: "100px" }} />
        </span>
      </div>
    </div>
  </div>
));

SkeletonParagraph.displayName = "SkeletonParagraph";

export default HomeBodyLeft;
