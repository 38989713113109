import React, {
	forwardRef,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import { useMediaQuery } from "@mui/material";

import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { useAuthContext } from "../context/auth";
import { useAuthModalContext } from "../context/authModal";
import { useToastContext } from "../context/toast";

import Axios from "./Axios";

import ArticleContext from "../libraries/context2";

import firebase from "../service/firebase";

import { ArticlesQuery } from "../server/query";

import "../css/Header.css";

const Header = forwardRef((_props, ref) => {
	const { openLoginModal, openRegisterModal } = useAuthModalContext();
	const { user } = useAuthContext();
	const { displayToast } = useToastContext();
	const articleContext = useContext(ArticleContext);

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const history = useHistory();

	const [articles, setArticles] = useState([]);
	const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

	const profileButton = useRef(/** @type {HTMLButtonElement | null} */ (null));

	useEffect(() => {
		FetchArticles();
	}, []);

	async function FetchArticles() {
		const response = await Axios({ query: ArticlesQuery });

		if (response.errors) {
			displayToast({
				message: "Something went wrong, please try again.",
				mode: "error",
			});
			return undefined;
		}

		const articles = response?.data.articals;
		const filterArticle = articles.filter((item) => Boolean(item?.main));
		const updateArticles = filterArticle
			?.map(parseArticle)
			.sort(
				(firstArticle, secondArticle) =>
					parseInt(firstArticle?.slotNo) - parseInt(secondArticle?.slotNo),
			);

		setArticles(updateArticles);
	}

	function navigateToArticle(option) {
		articleContext.setCurrentArticle(option.all);
		history.push("/article", option.all);
		window.localStorage.setItem("cArt", JSON.stringify(option.all));
		window.location.href = window.location.href;
	}

	return (
		<AppBar position="sticky" sx={{ px: 2, py: 1.5 }} ref={ref}>
			<Container maxWidth="xl" disableGutters>
				<Box display="flex" gap={3}>
					<Box display="inline-flex" alignItems="center" gap={3} flex="1 1 0">
						{!isMobile && (
							<Button
								disableElevation
								color="secondary"
								component={RouterLink}
								sx={{ color: "white" }}
								to="/"
							>
								Home
							</Button>
						)}
						{isMobile && (
							<IconButton component={RouterLink} to="/">
								<HomeIcon />
							</IconButton>
						)}
						<Box display="flex" alignItems="center" gap={0.5} flex="1 1 0">
							<SearchIcon />
							<Autocomplete
								fullWidth
								freeSolo
								size="small"
								options={articles}
								getOptionLabel={(option) => option?.heading ?? option}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={({ InputProps, ...params }) => (
									<TextField
										sx={{ maxWidth: "200px" }}
										color="secondary"
										placeholder="search"
										InputProps={{
											...InputProps,
											sx: {
												borderRadius: "999px",
												overflow: "hidden",
												bgcolor: "white",
											},
										}}
										{...params}
									/>
								)}
								renderOption={(props, option) => (
									<MenuItem
										{...props}
										onClick={() => navigateToArticle(option)}
									>
										{option.heading}
									</MenuItem>
								)}
							/>
						</Box>
					</Box>
					<Box>
						{user === null && (
							<Stack direction="row">
								<Button
									className="header_login"
									onClick={openLoginModal}
									variant="contained"
									disableElevation
									color="secondary"
								>
									Login
								</Button>
								{!isMobile && (
									<>
										<Divider
											orientation="vertical"
											light
											flexItem
											sx={{ mx: 1.5, borderRightWidth: "2px" }}
											variant="middle"
										/>
										<Button
											className="header_register"
											onClick={openRegisterModal}
											variant="contained"
											disableElevation
											color="secondary"
										>
											Register
										</Button>
									</>
								)}
							</Stack>
						)}
						{user !== null && (
							<>
								<IconButton
									ref={profileButton}
									onClick={() => setIsAccountMenuOpen(true)}
								>
									<AccountCircleIcon
										fontSize="large"
										className="accountCircle Icon"
									/>
								</IconButton>
								{profileButton.current && (
									<Menu
										open={isAccountMenuOpen}
										anchorEl={profileButton.current}
										onClose={() => setIsAccountMenuOpen(false)}
									>
										<MenuItem disableGutters sx={{ p: 0 }}>
											<MenuItem component={RouterLink} to="dashboard/profile">
												Profile
											</MenuItem>
										</MenuItem>
										<MenuItem onClick={() => firebase.logout()}>
											Logout
										</MenuItem>
									</Menu>
								)}
							</>
						)}
					</Box>
				</Box>
			</Container>
		</AppBar>
	);
});

Header.displayName = "Header";

function parseArticle(article) {
	return {
		category: article?.categoryName,
		image: article?.titleImage,
		heading: article?.title,
		para1: article?.pharagraph01,
		slotNo: article?.slotNo,
		place: article?.homePlace,
		all: article,
	};
}

export default Header;
