/**
 * @param {string} value
 * @param {number} maskStartIndex
 * @param {number} maskEndIndex
 * @returns {string}
 */
function maskString(value, maskStartIndex, maskEndIndex = value.length - 1) {
  const stringToMask = value.slice(maskStartIndex, maskEndIndex);
  return value.replace(stringToMask, "".padStart(stringToMask.length, "*"));
}

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  hour12: true,
  hour: "2-digit",
  minute: "2-digit",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

function formatDate(date) {
  console.log(date);
  return dateFormatter.format(date);
}

export { maskString, formatDate };
