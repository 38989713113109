import { useState } from "react";

/**
 * @param {{
 *   formSteps: (() => JSX.Element)[];
 *   schemas: (import("yup").ObjectSchema | undefined)[];
 * }} params
 */
function useMultiStepForm({ formSteps, schemas }) {
  const [currentStep, setCurrentStep] = useState(0);

  function previousStep() {
    if (currentStep > 0) setCurrentStep((currentStep) => currentStep - 1);
  }

  function nextStep() {
    if (currentStep < formSteps.length - 1)
      setCurrentStep((currentStep) => currentStep + 1);
  }

  const reset = () => setCurrentStep(0);

  return {
    CurrentStep: formSteps[currentStep],
    previousStep,
    nextStep,
    schema: schemas[currentStep],
    currentStepIndex: currentStep,
    reset,
  };
}

export default useMultiStepForm;
