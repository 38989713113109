import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Stack, Typography, styled } from "@mui/material";

import React from "react";

function Disclaimer({ setSwtDic, swtDic }) {
  return (
    <Dialog
      open={swtDic}
      onClose={() => setSwtDic(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { p: 6, borderRadius: 4 } }}
    >
      <DialogTitle sx={{ fontWeight: 600 }}>Disclaimer</DialogTitle>
      <DialogContent>
        <Stack gap={2.5}>
          <Paragraph>
            This magazine does not serve any political direction, it is created
            in good faith that knowledge of the content might be beneficial to
            the next generation to better perform their role in shaping the
            society.
          </Paragraph>
          <Paragraph>
            The journal respects the constitution and all laws of the Democratic
            Socialist Republic of Sri Lanka, but takes academic freedom in
            publishing research findings.
          </Paragraph>
          <Paragraph>
            Claus-Juergen Grossklaus, Diplom-Industrial Engineer for Maritime
            Transport and Production Management, is responsible for the content
            of the articles in this magazine within the meaning of media law.
            Articles that have been taken over from another author are the
            responsibility of the respective author.
          </Paragraph>
          <Paragraph>
            This on-line magazine serves to expand the knowledge of interested
            readers. All information, images and graphics taken from the
            Internet serve only this purpose. It is not allowed to copy parts or
            whole articles and use them for commercial purposes.
          </Paragraph>
          <Paragraph>
            If parts of the magazine or individual articles are copied, it is
            only permitted if the articles or their parts are presented in the
            overall context. If parts of the magazine or articles are taken over
            and taken out of their context, they are not considered to be the
            origin of the magazine.
          </Paragraph>
          <Typography component="address" sx={{ fontStyle: "normal", mt: 2 }}>
            <Typography variant="body1" mb={2}>
              The Editor
            </Typography>

            <Paragraph textAlign="left">
              7/8 Nadun Uyana, <br />
              Keppetipola, <br />
              71507 Makehelwala, <br />
              Sri Lanka <br />
              E-Mail <a>respectsrilanka@gmail.com</a>
            </Paragraph>
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

const Paragraph = styled(Typography)({
  textAlign: "justify",
  lineHeight: 1.5,
  fontFamily: "Arial, Helvetica, sans-serif",
});

export default Disclaimer;
