/**
 * @param {{
 *   id: string;
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 *   address: string | null;
 *   mobileNumber: string;
 *   isSubscribed: boolean;
 *   occupation: string;
 *   education: string;
 * }} param
 * @returns String
 */
function createUser({
  id,
  firstName,
  lastName,
  email,
  address,
  occupation,
  education,
  isSubscribed,
  mobileNumber,
}) {
  return /* GraphQL */ `
    mutation {
      createUser(
        data: {
          id: "${id}"
          occupation: "${occupation}"
          education: "${education}"
          firstName: "${firstName}"
          lastName: "${lastName}"
          email: "${email}"
          address: ${address && `"""${address}"""`}
          mobileNumber: "${mobileNumber}"
          isSubscribed: ${isSubscribed}
        }
      )  {
          __typename
          ... on User {
            id
          }
          ... on FieldError{
            path
            message
          }
  }
    }
  `;
}

/**
 * @param {{
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 *   address: string | null;
 *   mobileNumber: string;
 *   isSubscribed: boolean;
 *   fbUID: string;
 *   occupation: string;
 *   education: string;
 * }} param
 * @returns String
 */
function updateUser({
  firstName,
  lastName,
  email,
  address,
  occupation,
  education,
  isSubscribed,
  mobileNumber,
}) {
  return /* GraphQL */ `
   mutation {
     updateUser(
       data: {
         occupation: "${occupation}"
         education: "${education}"
         firstName: "${firstName}"
         lastName: "${lastName}"
         email: "${email}"
         address: ${address && `"""${address}"""`}
         mobileNumber: "${mobileNumber}"
         isSubscribed: ${isSubscribed}
       }
     )  {
         __typename
         ... on User {
           occupation
           education
           firstName
           lastName
           email
           address
           mobileNumber
           isSubscribed
         }
         ... on FieldError {
           path
           message
         }
 }
   }
 `;
}

function sendVerificationEmail(/** @type {{ email: string }} */ { email }) {
  return /* GraphQL */ `
    mutation {
     sendVerificationEmail(where: { email: "${email}" }) {
       __typename
       ... on VerificationCodeOutput {
         referenceId
       }
       ... on FieldError {
         path
         message
       }
     }
   }
  `;
}

function sendPasswordResetEmail({email}) {
  return /* GraphQL */ `
    mutation {
      sendPasswordResetEmail(where: { email: "${email}" }) {
        __typename
        ... on VerificationCodeOutput {
          referenceId
        }
        ... on FieldError {
          path
          message
        }
      }
    }
  `;
}

function confirmVerificationCode(
  /** @type {{ referenceId: string; code: string }} */ { referenceId, code },
) {
  return /* GraphQL */ `
  mutation {
    confirmVerificationCode(
      where: { referenceId: "${referenceId}" }
      data: { code: "${code}" }
    ) {
      success
    }
  }
  `;
}

function updatePassword({ referenceId, password }) {
  return /* GraphQL */ `
    mutation {
      updatePassword(where: { referenceId: "${referenceId}" }, data: { password: "${password}" }) {
        id
      }
    }
  `;
}

export {
  createUser,
  updateUser,
  sendVerificationEmail,
  confirmVerificationCode,
  sendPasswordResetEmail,
  updatePassword,
};
