import Skeleton from "@mui/material/Skeleton";

import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import Axios from "./Axios";

import ArticleContext from "../libraries/context2";

import * as Slots from "../Config/Slots";

import { SlotsWithArticleQuery } from "../server/query";

import "../css/HomeBodyRightContainer.css";

function HomeBodyRightContainer() {
  const context = useContext(ArticleContext);
  const history = useHistory();
  const [article, setArticle] = useState([]);

  useEffect(() => {
    FetchArticle();
  }, []);

  useEffect(() => {
    if (article?.length !== 0) {
      article?.map((item) => {
        window.document.querySelector(`#${item?.span1}`).innerHTML =
          item?.para1;
        window.document.querySelector(`#${item?.span2}`).innerHTML =
          item?.para2;
        let AllParagraphs = window.document.querySelectorAll(
          `#${item?.span1} p`,
        );
        AllParagraphs.forEach((item) => {
          if (item.querySelector("span")) {
            item.querySelector("span").style.lineHeight = "1.5";
            // item.querySelector("span").style.fontSize = "12pt";
            item.querySelector("span").style.fontFamily =
              "Arial, Helvetica, sans-serif";
          } else {
            item.style.lineHeight = "1.5";
            // item.style.fontSize = "12pt";
            item.style.fontFamily = "Arial, Helvetica, sans-serif";
          }
        });
        let AllParagraphs2 = window.document.querySelectorAll(
          `#${item?.span2} p`,
        );
        AllParagraphs2.forEach((item) => {
          if (item.querySelector("span")) {
            item.querySelector("span").style.lineHeight = "1.5";
            // item.querySelector("span").style.fontSize = "12pt";
            item.querySelector("span").style.fontFamily =
              "Arial, Helvetica, sans-serif";
          } else {
            item.style.lineHeight = "1.5";
            // item.style.fontSize = "12pt";
            item.style.fontFamily = "Arial, Helvetica, sans-serif";
          }
        });
      });
    }
  }, [article]);

  const FetchArticle = () => {
    Axios({ query: SlotsWithArticleQuery, title: "slots" }).then((res) => {
      const newFirst = res?.data;

      if (newFirst !== undefined && newFirst?.slots !== null) {
        const filterArticle = newFirst?.slots?.filter(
          (item) =>
            item?.artical?.main === true &&
            item?.place === "MAIN" &&
            (item?.slot === Slots?.mainSlot1 ||
              item?.slot === Slots?.mainSlot2),
        );
        const newSort = filterArticle?.sort(
          (a, b) => parseInt(a?.slot) - parseInt(b?.slot),
        );
        let count = 0;
        const updateArticles = newSort?.map((item) => {
          const para1 = item?.artical?.pharagraph01;
          const para2 = item?.artical?.pharagraph02;
          count++;

          return {
            id: item?.artical?.id,
            category: item?.artical?.categoryName,
            image: item?.artical?.titleImage,
            heading: item?.artical?.title,
            para1,
            para2,
            slotNo: item?.artical?.slotNo,
            place: item?.artical?.homePlace,
            all: item?.artical,
            span1: `para${count}`,
            span2: `sara${count}`,
          };
        });
        setArticle(updateArticles);
      } else
        console.error(
          `Errors articles on ${JSON.stringify(newFirst?.errors[0]?.message)}`,
        );
    });
  };

  return (
    <div className="homeBodyRightContainer">
      {article?.length == 0 ? (
        <>
          <div className="topic1">
            <Skeleton variant="text" width="100px" />
          </div>
          <div className="right_container1">
            <p />
            <div className="texts">
              <span className="right_container1Heading">
                <Skeleton variant="text" width="40%" />
              </span>
              <div className="right_container1Content">
                <span>
                  <Skeleton
                    variant="rounded"
                    style={{
                      width: "100%",
                      height: "160px",
                    }}
                  />
                </span>
                <span>
                  <Skeleton
                    variant="rounded"
                    style={{
                      width: "100%",
                      height: "160px",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {article?.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <div className="topic1">
                  <span>{item?.category}</span>
                </div>
                <div className="right_container1">
                  <p />
                  <div
                    className="texts"
                    onClick={() => {
                      context.setCurrentArticle(item?.all);
                      try {
                        window.localStorage.setItem(
                          "cArt",
                          JSON.stringify(item?.all),
                        );
                      } catch (error) {}
                      history.push("/article", item?.all);
                    }}
                  >
                    <span className="right_container1Heading">
                      {item?.heading}
                    </span>
                    <div className="right_container1Content">
                      <span id={item?.span1}></span>
                      <span id={item?.span2}></span>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
    </div>
  );
}

export default HomeBodyRightContainer;
