import React, { useContext } from "react";

import { useHistory } from "react-router-dom";

import ArticleContext from "../libraries/context2";

import "../css/BodyLeftContainer.css";

function BodyLeftContainer({ title, all, span }) {
  const history = useHistory();
  const context = useContext(ArticleContext);

  function navigateToArticlePage() {
    context.setCurrentArticle(all);
    window.localStorage.setItem("cArt", JSON.stringify(all));
    history.push("/article", all);
  }

  return (
    <div className="bodyLeftContainer">
      <div className="textContainer" onClick={navigateToArticlePage}>
        <div className="left_containerHeading">
          <span>{title}</span>
        </div>
        <div className="left_containerContent">
          <span id={span} />
        </div>
      </div>
    </div>
  );
}

export default BodyLeftContainer;
