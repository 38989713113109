import { Box, FormControl, FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import * as yup from "yup";

function EmailStep() {
  const form = useFormContext();

  return (
    <>
      <Box component="label" htmlFor="email" mb={0.5} display="inline-block">
        Email
      </Box>
      <Box
        component={FormControl}
        display="grid"
        rowGap={0.5}
        columnGap={1}
        gridTemplateColumns="1fr auto"
      >
        <Controller
          name="email"
          control={form.control}
          render={({ field: { ref, ...field }, fieldState }) => (
            <>
              <TextField
                inputMode="email"
                autoComplete="email"
                fullWidth
                id="email"
                size="small"
                placeholder="Email associated with the account."
                inputRef={ref}
                error={!!fieldState.error}
                {...field}
              />
              <Button
                type="submit"
                variant="contained"
                disableElevation
                disabled={form.formState.isSubmitting}
              >
                Submit
              </Button>
              <FormHelperText
                error={!!fieldState.error}
                sx={{ gridColumn: "span 2" }}
              >
                {fieldState.error?.message}
              </FormHelperText>
            </>
          )}
        />
      </Box>
    </>
  );
}

const schema = yup.object({
  email: yup.string().trim().required().email().lowercase().label("Email"),
});

export default EmailStep;
export { schema };
