import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";

import React, { useEffect } from "react";

import {
  Controller,
  useFormContext as useHookFormContext,
} from "react-hook-form";

import OtpInput from "react-otp-input";

import * as yup from "yup";

import { useFormContext } from "./formContext";

import { maskString } from "../../util/helper";

function EmailConfirmationStep() {
  const { setTitle, setDialogMaxWidth } = useFormContext();
  const form = useHookFormContext();

  useEffect(() => {
    /** @type {string} */
    const email = form.getValues().email;
    const maskedEmail = maskString(email, 2, email.lastIndexOf("@"));

    setTitle(`Enter the code sent to ${maskedEmail}`);
    setDialogMaxWidth("sm");
  }, []);

  function handleChange(name, value) {
    form.setValue(name, value, { shouldValidate: true });
  }

  return (
    <Stack alignItems="center" gap={2}>
      <FormControl>
        <Controller
          name="verificationCode"
          render={({ field: { value, name }, fieldState }) => (
            <OtpInput
              value={value}
              containerStyle={{ gap: ".5em" }}
              onChange={(value) => handleChange(name, value)}
              shouldAutoFocus
              inputType="tel"
              numInputs={5}
              inputStyle={{ width: "72px", height: "72px" }}
              renderInput={({ ref, ...props }) => (
                <TextField
                  error={Boolean(fieldState.error)}
                  inputRef={ref}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "1.5rem" },
                  }}
                  {...props}
                />
              )}
            />
          )}
        />
        <FormHelperText error={!!form.getFieldState("verificationCode").error}>
          {form.getFieldState("verificationCode").error?.message ??
            "Please check the spam folder too and try again."}
        </FormHelperText>
      </FormControl>

      <Button
        type="submit"
        disabled={form.formState.isSubmitting}
        variant="contained"
        sx={{ width: 1 / 2 }}
      >
        Verify
      </Button>
    </Stack>
  );
}

const schema = yup.object({
  verificationCode: yup
    .string()
    .trim()
    .required()
    .length(5, "Invalid verification code.")
    .label("Verification Code"),
});

export { schema };
export default EmailConfirmationStep;
