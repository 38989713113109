import React from "react";

/**
 * @template {React.ElementType} C
 * @param {{
 *   children: React.ReactNode;
 *   condition?: boolean;
 *   component: C;
 * } & React.ComponentPropsWithRef<C>} props
 */

function WithComponent(props) {
  const { condition, children, component: Component, ...rest } = props;

  if (condition) return <Component {...rest}>{children}</Component>;

  return children;
}

export default React.memo(WithComponent);
